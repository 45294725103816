import axios from "axios";
export const UploadFile = (url, data, index, callback) => {
  const config = {
    onUploadProgress: function(progressEvent) {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      callback(percentCompleted, index);
    }
  };
  return axios.post(url, data, config).then(
    reps => reps.data,
    error => {
      throw error;
    }
  );
};
