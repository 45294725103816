<template>
  <div class="AImagePick" :id="'w_' + id" :class="[drag ? 'drag' : false]">
    <label class="d-flex align-items-center" :for="id">
      <AIcon name="clip" />
      <span>{{ $t("attach_file") }}</span>
    </label>
    <input
      @change="pick"
      :id="id"
      class="AImagePick__file-pick"
      type="file"
      :multiple="!oneFile"
    />
    <div
      v-show="images.length || drag"
      class="AImagePick__list"
      :class="[images.length ? 'has-img' : false]"
      :id="'l_' + id"
    >
      <perfect-scrollbar>
        <div
          class="AImagePick__list-item"
          :class="[typeof cTmpFiles[k] === 'string' ? 'uploaded' : false]"
          v-for="(img, k) in images"
          :key="k"
        >
          <button
            v-show="!cTmpFiles[k]"
            type="button"
            @click="remove(k)"
            :data-index="k"
          >
            <AIcon width="23" height="23" name="remove-button" />
          </button>
          <img
            :src="img"
            :alt="`${name}_${k}`"
            :style="{
              opacity:
                uploadStart && cTmpFiles[k] < 2 ? 0.1 : cTmpFiles[k] / 100
            }"
          />
          <b-progress
            v-if="cTmpFiles[k] && typeof cTmpFiles[k] === 'number'"
            :value="cTmpFiles[k]"
            :max="100"
            :precision="2"
            class="AImagePick__progress"
          />
        </div>
      </perfect-scrollbar>
    </div>
    <p class="input-group__sub-description">{{ subDescription }}</p>
  </div>
</template>

<script>
import { UploadFile } from "@/api/file.api";
import { idsGen } from "@/utils/idsGen";

export default {
  name: "AImagePick",
  props: {
    name: String,
    uploadUrl: String,
    uploaded: undefined,
    uploadedFiles: [Array, String],
    uploadStart: Boolean,
    subDescription: [String, Number],
    onlyPath: Boolean,
    oneFile: Boolean
  },
  data() {
    return {
      id: "aImagePick_" + Date.now(),
      files: [],
      images: [],
      drag: false,
      counter: 0,
      tmpFiles: {},
      uploadedFilesArray: []
    };
  },
  watch: {
    uploadedFiles(fl) {
      if (fl.length === 0) {
        this.tmpFiles = {};
        this.images = [];
      }
    },
    async uploadStart(val) {
      if (val) await this.uploadFile();
    }
  },
  computed: {
    cUploadedFiles() {
      return this.uploadedFilesArray;
    },
    cTmpFiles: {
      get() {
        return this.tmpFiles;
      },
      set(val) {
        this.tmpFiles = val;
      }
    }
  },
  mounted() {
    this.setEvents();
  },
  methods: {
    pick(e) {
      let files = e.type === "drop" ? e.dataTransfer.files : e.target.files;
      this.renderAndSetValues(files);
    },
    remove(e) {
      this.files.splice(e, 1);
      this.images.splice(e, 1);
      this.$emit("update:files", this.files);
    },
    renderAndSetValues(files = []) {
      for (let i in files) {
        if (typeof files[i] !== "object") continue;
        this.fileInput(files[i], i);
      }
    },
    fileInput(file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => {
        if (this.oneFile) {
          this.images = [];
          this.files = [];
        }
        this.images.push(e.target.result);
        this.files.push(file);
        this.$emit("update:files", this.files);
      };
    },
    uploadFile() {
      if (this.files.length) {
        for (const file of this.files) {
          let i = this.files.indexOf(file);
          let f = { file }.json2FormData();
          UploadFile(this.uploadUrl, f, i, (pc, i) => {
            this.$set(this.cTmpFiles, i, pc);
          }).then(e => {
            this.$set(this.cTmpFiles, i, e.path);
            if (this.onlyPath) {
              if (this.oneFile) this.uploadedFilesArray = e.path;
              else this.uploadedFilesArray.push(e.path);
            } else {
              this.uploadedFilesArray.push({
                id: 0,
                uid: idsGen() + Date.now(),
                img: e.path,
                path: e.path
              });
            }
            this.checkFile();
          });
        }
      } else {
        this.checkFile();
      }
    },
    checkFile() {
      this.$emit("update:uploadedFiles", this.uploadedFilesArray);
      if (this.oneFile || this.uploadedFilesArray.length === this.files.length)
        this.$emit("uploaded", true);
      this.$emit("update:uploadStart", false);
    },
    setEvents() {
      let target = document.body;
      target.addEventListener("dragover", e => {
        e.preventDefault();
        this.drag = true;
      });
      target.addEventListener("dragenter", e => {
        e.preventDefault();
        this.counter++;
      });
      target.addEventListener("dragleave", () => {
        this.counter--;
        if (!this.counter) this.drag = false;
      });
      target.addEventListener("drag", e => {
        e.preventDefault();
        this.drag = false;
      });
      document.getElementById("w_" + this.id).addEventListener("drop", e => {
        e.preventDefault();
        this.counter = 0;
        this.drag = false;
        this.pick(e);
      });
    }
  }
};
</script>

<style scoped lang="scss">
.AImagePick {
  &__pack {
    display: none;
  }
  &__list {
    transition: all 300ms ease;
    border-radius: 10px;
    padding-left: 5px;
    margin-bottom: 15px;
    .ps {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      max-height: 217px;
    }

    &.has-img {
      border-style: solid;
      border-color: rgba(135, 149, 168, 0.1);
    }

    &-item {
      width: 65px;
      height: 65px;
      position: relative;
      margin: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.uploaded {
        &:after {
          content: "";
          width: 10px;
          height: 10px;
          border-radius: 10em;
          background-color: var(--th-orange);
          position: absolute;
          bottom: 5px;
          right: 5px;
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
        transition: all 300ms ease 0s;
      }

      button {
        position: absolute;
        padding: 0;
        border: none;
        right: 3px;
        top: 3px;
        background: none;
      }
    }
  }

  &.drag {
    .AImagePick__list {
      border: 4px dashed var(--th-orange);
      min-height: 100px;
    }
  }

  label {
    padding: 12px 15px;
    border: 4px solid fade-out(#b4cdfe, 0.75);
    font-size: 0.875rem;
    border-radius: 10px;
    cursor: pointer;
    text-transform: uppercase;

    span {
      display: block;
      margin-left: calc(100% / 2 - 80px);
    }
  }

  input[type="file"] {
    display: none;
  }
}
.upload {
  position: absolute;
}
.input-group__sub-description {
  font-style: italic;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: var(--th-gray-dark);
}
</style>
